import { Component } from "react";


class GoogleMap extends Component {
    render() { 
        return (
            <div className="map">
                <div className="container-fluid px-0">
                    <div className="map__wrapper">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387190.2799120522!2d-74.25987460306818!3d40.697670064076384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1647360575411!5m2!1sen!2sbd" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default GoogleMap;