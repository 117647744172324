import { Component } from "react";


const title = <h2> <span className="color--gradient-y"> Bored Apes</span> Roadmap</h2>;
const desc = "This is our roadmap so far, but this is only the beginning we want to build our project around our community, so if you have more ideas we are all ears";

let roadmapList = [
    {
        imgUrl: 'assets/images/roadmap/01.png',
        imgAlt: 'Roadmap Icon',
        title: 'Launch Initiaed',
        desc: '6666 crypto bored apes will be ready to minted from he crypto stay tuned for the white list!',
    },
    {
        imgUrl: 'assets/images/roadmap/02.png',
        imgAlt: 'Roadmap Icon',
        title: 'Physical Merch',
        desc: '6666 crypto bored apes will be ready to minted from he crypto stay tuned for the white list!',
    },
    {
        imgUrl: 'assets/images/roadmap/03.png',
        imgAlt: 'Roadmap Icon',
        title: 'The Apes Lands',
        desc: '6666 crypto bored apes will be ready to minted from he crypto stay tuned for the white list!',
    },
    {
        imgUrl: 'assets/images/roadmap/04.png',
        imgAlt: 'Roadmap Icon',
        title: 'Bored Apes Game',
        desc: '6666 crypto bored apes will be ready to minted from he crypto stay tuned for the white list!',
    },
    {
        imgUrl: 'assets/images/roadmap/05.png',
        imgAlt: 'Roadmap Icon',
        title: 'Launch Metaverse',
        desc: '6666 crypto bored apes will be ready to minted from he crypto stay tuned for the white list!',
    },
    {
        imgUrl: 'assets/images/roadmap/06.png',
        imgAlt: 'Roadmap Icon',
        title: 'Upcoming Dapps',
        desc: '6666 crypto bored apes will be ready to minted from he crypto stay tuned for the white list!',
    },
]



class RoadMap extends Component {
    render() { 
        return (
            <section className="roadmap padding-top padding-bottom" id="roadmap">
                <div className="container">
                    <div className="section-header aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <div className="section-header__content">
                            {title}
                            <p>{desc}</p>
                        </div>
                    </div>
                    <div className="roadmap__wrapper">
                        <div className="row g-4">
                            {roadmapList.map((val, i) => (
                                <div className="col-lg-4 col-md-6" key={i}>
                                    <div className="roadmap__item aos-animate aos-init" data-aos="fade-left" data-aos-duration="1000">
                                        <div className="roadmap__inner">
                                            <div className="roadmap__thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="roadmap__content">
                                                <h4>{val.title}</h4>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default RoadMap;